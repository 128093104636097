<template>
  <Layout el="hm fixed">
    <Header>
      <List el="column-between xsm" class="v-center">
        <List el="column xsm" class="v-center">
          <Icon name="arrow_back" @click="close" />
          <svg
            id="mobileLogo"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 249.7 249.7"
            el="logo"
          >
            <path
              d="M137,48.71,13.29,15.56l33.14,123.7a102.78,102.78,0,0,0,102.79,76.11L188,214.05l22,22h23.81L211,213.26h0L38,40.29l32.24,8.43,82.65,82.65q.78-27.91,1.58-55.82a80.17,80.17,0,0,1,16.68,14.73l-1.71,57.65,42.31,42.3,1.33-38.73A102.79,102.79,0,0,0,137,48.71Z"
              class="logo-symbol"
            ></path>
          </svg>
          <Text el="h4">{{ formatFilename }}</Text>
        </List>
        <List el="column xsm" class="v-center">
          <!-- <Icon name="file_download" @click="close" /> -->
        </List>
      </List>

      <!-- <List el="xsm" style="padding:32px;">
        <List el="column-between" class="v-center">
          <Text el="h3" v-if="file != null">{{ file.label }}</Text>
          <Text el="h3" v-else>File does not exist</Text>
          <Icon name="close" @click="close" />
        </List>
      </List> -->
    </Header>
    <main el="main">
      <div v-if="url != null">
        <img :src="url" alt="image" loading="lazy" v-if="fileType == 'image'" />
        <video
          :src="url"
          loading="lazy"
          v-else-if="fileType == 'video'"
          controls
          @contextmenu="preventDownload($event)"
          controlsList="nodownload"
        />
        <iframe
          :src="url"
          frameborder="0"
          v-else-if="fileType == 'txt'"
          class="txt"
        ></iframe>
        <iframe :src="url" frameborder="0" v-else></iframe>
        <!-- <Text el="p">Text</Text> -->
      </div>
    </main>
  </Layout>
</template>
<script>
let includes = require("lodash/includes");
import router from "../router";
// import { db } from "@/firebase";
// import { doc, getDoc } from "firebase/firestore";
import Icon from "@/components/btn/Icon.vue";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      file: null,
      url: null,
    };
  },
  watch: {
    "$route.params.path": function() {
      this.getFile();
    },
  },
  methods: {
    preventDownload(e) {
      //do stuff
      e.preventDefault();
    },
    async getFile() {
      // let routeParams = this.$route.params.path;
      // if (routeParams != null) {
      //   const docRef = doc(db, "recordings", this.$route.params.path);
      //   const docSnap = await getDoc(docRef);
      //   this.recording = docSnap.data();
      // }
      let x = this;
      if (
        this.$route.params.path != null &&
        this.$route.params.filename != null
      ) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          this.$route.params.collection +
            "/" +
            this.$route.params.path +
            "/" +
            this.$route.params.filename
        );

        getDownloadURL(storageRef).then((downloadURL) => {
          // console.log("File available at", downloadURL);
          x.url = downloadURL;
        });
      }
    },
    close() {
      // this.section.collapse = true;
      router.go(-1);
    },
  },
  mounted() {
    this.getFile();
  },
  computed: {
    formatFilename() {
      let filename = this.$route.params.filename;
      // console.log(filename);
      if (filename != null) {
        let nameArr = filename.split(".");
        nameArr.pop();
        return nameArr.join("");
      } else {
        return "";
      }
    },
    fileType() {
      const file = this.$route.params.filename;
      let fileArr = file.split(".");
      const fileExt = fileArr.pop();
      if (includes(["jpg", "png", "webp"], fileExt)) {
        return "image";
      } else if (includes(["mp4"], fileExt)) {
        return "video";
      } else if (includes(["txt"], fileExt)) {
        return "txt";
      } else {
        return "iframe";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
[el="header"] {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
}
[el="main"] {
  --bg-color: rgba(0, 0, 0, 0.85);
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
}
.v-center {
  display: grid;
  align-items: center;
}
iframe,
video {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
  left: 0;
  top: 0;
}
img {
  width: calc(100% - 40px);
  height: 100%;
  max-width: 1200px;
  max-height: 100%;
  // object-fit: contain;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.25);
  margin: 20px;
  box-sizing: border-box;
}
#mobileLogo {
  height: 35px;
}
.logo-symbol {
  fill: var(--color-ocean);
}
.txt {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 8.5in;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  margin: 20px 0;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
}
</style>
